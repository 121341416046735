<template>
  <v-container class="my-10">
    <v-dialog v-model="loading" fullscreen>
      <v-container
        fluid
        fill-height
        style="background-color: rgba(255, 255, 255, 0.5)"
      >
        <v-layout justify-center align-center>
          <v-progress-circular indeterminate color="primary">
          </v-progress-circular>
        </v-layout>
      </v-container>
    </v-dialog>

    <!-- <v-dialog v-model="showReport">

    </v-dialog> -->

    <v-row class="d-flex align-center justify-center" v-if="isError">
      <v-col cols="12" md="4">
        <v-dialog v-model="isError" width="20%">
          <v-card>
            <v-card-title class="d-flex align-center justify-center">
              <div>
                <v-icon large color="#c7ae78">mdi-alert-circle-outline</v-icon>
              </div>
            </v-card-title>
            <v-card-text class="d-flex align-center justify-center">
              <div>
                {{ errorMessage }}
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-action
              class="d-flex align-center justify-center"
              style="background-color: #c7ae78"
            >
              <span @click="isError = false"><v-btn text>Dismiss</v-btn></span>
            </v-card-action>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row class="d-flex align-center justify-center" v-if="createFailed">
      <v-col cols="12" md="4">
        <v-dialog v-model="isError" width="20%">
          <v-card>
            <v-card-title class="d-flex align-center justify-center">
              <div>
                <v-icon large color="#c7ae78">mdi-alert-circle-outline</v-icon>
              </div>
            </v-card-title>
            <v-card-text class="d-flex align-center justify-center">
              <div>
                {{ errorMessage }}
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions
              class="d-flex align-center justify-center"
              style="background-color: #c7ae78"
            >
              <span @click="isError = false"><v-btn text>Dismiss</v-btn></span>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-card v-if="status == 'creating'">
      <v-card-title>Create Product</v-card-title>
      <v-card-text style="width: 100%">
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="6">
              <v-row>
                <v-col cols="12" md="8">
                  <v-select
                    required
                    :items="vendors"
                    item-text="name"
                    item-value="id"
                    :rules="vendorRules"
                    v-model="newProduct.vendorId"
                    label="Vendor"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="8">
                  <v-file-input
                    @change="uploadPrimaryImage"
                    accept="image/*"
                    label="Primary Image"
                    required
                    :rules="imageRules"
                  ></v-file-input>
                  {{ previewPrimaryImage }}
                  <vue-cropper v-if="previewPrimaryImage"
      ref="cropper"
      :guides="true"
      :src="previewPrimaryImage"
      :aspectRatio="aspectRatio"
    ></vue-cropper>
                </v-col>
                <button @click="cropAndUpload" type="button">Crop and Upload</button>
                
              </v-row>
             
              
              <v-row style="width: 100%">
                <v-col cols="12" md="8">
                  <v-file-input
                    v-model="newProduct.images"
                    accept="image/*"
                    label="Images"
                    multiple
                    counter="3"
                    required
                    :rules="imageRules"
                  ></v-file-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="8">
                  <v-text-field
                    required
                      
                    v-model="newProduct.name"
                    label="Name"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="8">
                  <v-text-field
                    v-model="newProduct.price"
                    label="Price"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="8">
                  <v-text-field
                    v-model="newProduct.sizes"
                    label="Sizes"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="8">
                  <v-textarea
                    required
                    :rules="descriptionRules"
                    v-model="newProduct.description"
                    label="Description"
                    clearable
                    clear-icon="mdi-close-circle"
                    auto-grow
                    rows="3"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6" v-if="showImage">
              <img
                class="mt-4"
                :src="previewPrimaryImage"
                style="height: 30vh"
                crossorigin="anonymous"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="2">
              <v-btn @click="validation()" id="button" :disabled="isDisabled"
                >Submit</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <div v-if="status == 'created'">
      <VerifyProductTag
        :image="previewImage"
        :features="features"
        :topCategory="topCategory"
        :category="category"
        :productId="productId"
        v-on:selectTags="selectTags"
        v-on:editTags="status = 'editTag'"
      ></VerifyProductTag>
    </div>

    <div v-if="status == 'selectTag'">
      <SelectProductTag
        :image="previewImage"
        :features="features"
        :topCategory="topCategory"
        :category="category"
        :productId="productId"
      ></SelectProductTag>
    </div>

    <v-card v-if="status == 'editTag'">
      <v-card-title>Edit Product Tag</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="4">
            <div>
              <img
                class="mt-4"
                :src="previewPrimaryImage"
                style="height: 50vh; max-width: 100%;"
                crossorigin="anonymous"
              />
            </div>
          </v-col>

          <!-- Update Product Form -->
          <v-col>
            <UpdateProductTag
              :productId="productId"
              :formLoader="formLoader"
              @status-change="handleStatusChange"
            ></UpdateProductTag>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ProductService from "../../services/ProductService";
import VendorService from "../../services/VendorService";
import UpdateProductTag from "../../components/UpdateProductTag.vue";
import VerifyProductTag from "../../components/VerfiyProductTag.vue";
import SelectProductTag from "../../components/SelectProductTag.vue";
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

export default {
  data: () => ({
    aspectRatio: 341 / 512,
    newProduct: {
      images: null,
      primaryImage: null,
      name: "",
      vendorId: null,
      description: "",
      price: 0,
    },
    previewImage: [],
    previewPrimaryImage: '',
    valid: false,
    showImage: false,
    loading: false,
    isError: false,
    isDisabled: false,
    createFailed: false,
    isCreated: false,
    reportDialog: false,
    isCorrect: true,
    formLoader: false,
    status: "creating",
    url: null,
    productId: null,
    features: [],
    vendors: [],
    topCategory: "",
    category: "",
    loader: false,
    errorMessage: "",
    imageRules: [(v) => !!v || "Image is required"],
    nameRules: [(v) => !!v || "Name is required"],
    descriptionRules: [
      (v) => !!v || "Description is required",
      (v) => v.length <= 250 || "Max 250 characters",
    ],
    vendorRules: [(v) => !!v || "Vendor is required"],
  }),
  components: { SelectProductTag, VerifyProductTag, UpdateProductTag,VueCropper },
  watch: {
    previewPrimaryImage(newValue) {
      // If the imageUrl changes, initialize the cropper
      if (newValue) {
        this.$nextTick(() => {
          // Initialize the cropper when the DOM has been updated
          this.$refs.cropper.replace(newValue);
        });
      }
    },
    "newProduct.images"(item) {
      let previewImages = [];
      for (let i = 0; i < item.length; i++) {
        previewImages[i] = URL.createObjectURL(item[i]);
      }
      this.previewImage = previewImages;
    },
    "newProduct.vendorId"(item) {
      localStorage.setItem("selectedVendorId", item);
    },
  },
  created() {
    this.getVendors();
  },
  methods: {
  
    getVendors() {
      VendorService.fetchVendorRecord().then((response) => {
        let requiredData = response;
        this.vendors = requiredData;
      });
      this.newProduct.vendorId = JSON.parse(
        localStorage.getItem("selectedVendorId")
      );
    },
    uploadPrimaryImage(image) {
      this.newProduct.primaryImage = image;
      console.log(this.newProduct.primaryImage)
      this.previewPrimaryImage = URL.createObjectURL(image);
      this.showImage = true;
    },
   
    cropAndUpload() {
      const cropper = this.$refs.cropper;

      if (!cropper) {
        console.error('Cropper instance is not available');
        return;
      }

      const croppedCanvas = cropper.getCroppedCanvas();
      if (!croppedCanvas) {
        console.error('Cropper canvas is empty');
        return;
      }

      const aspectRatio = this.aspectRatio;
      const croppedWidth = croppedCanvas.width;
      const croppedHeight = croppedCanvas.height;

      // New canvas dimensions based on aspect ratio
      const canvasWidth = croppedWidth;
      const canvasHeight = canvasWidth / aspectRatio;

      // Create new canvas
      const newCanvas = document.createElement('canvas');
      newCanvas.width = canvasWidth;
      newCanvas.height = canvasHeight;

      const ctx = newCanvas.getContext('2d');

      // Fill the new canvas with a white background
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvasWidth, canvasHeight);

      // Calculate y offset to center the cropped image vertically
      const yOffset = (canvasHeight - croppedHeight) / 2;

      // Draw the cropped image onto the new canvas
      ctx.drawImage(croppedCanvas, 0, yOffset);

      // Convert the new canvas to a Blob
      newCanvas.toBlob((blob) => {
        if (!blob) {
          console.error('Failed to create Blob from canvas');
          return;
        }

        // Create an object URL from the Blob and assign it to previewPrimaryImage
        const fileName = `cropped_image.${blob.type.split('/')[1]}`;
        const file = new File([blob], fileName, { type: blob.type });
        const url = URL.createObjectURL(file);
        this.previewPrimaryImage = url;
        this.newProduct.primaryImage = file;
      }, 'image/jpeg');
    },
    async createProduct() {
      console.log(this.newProduct.primaryImage);
      this.loading = true;
      // this.newProduct.vendorId = this.selectedVendor;
      let requiredObj = this.newProduct;
      if (String(requiredObj.price) == ""){
        requiredObj.price = 0
      } else {
        requiredObj.price = parseInt(requiredObj.price);
      }
      await ProductService.createProduct(requiredObj).then((response) => {
        if (response.message == "success") {
          let features = response.data.formattedTags;
          features.forEach((item) => {
            if (item.key === "Category") {
              let categories = item.value.split("/");
              // this.topCategory = categories[0];
              this.category = categories[1];
            } else if (item.key === "Top Category") {
              this.topCategory = item.value;
            } else {
              this.features.push(item);
            }
          });
          this.productId = response.data.product.id;
          this.status = "created";
          this.loading = false;
          // this.$router.push("/products");
        } else if (response == "Duplicate Product name") {
          this.loading = false;
          this.isError = true;
          this.errorMessage = response;
          this.isDisabled = false;
        } else {
          this.createFailed = true;
          this.errorMessage = "Network Error";
          this.isDisabled = false;
        }
      });
    },
    validation() {
      if (this.$refs.form.validate()) {
        this.valid = true;
        (this.isDisabled = true), this.createProduct();
      } else {
        this.valid = false;
      }
    },
    showProducts() {
      this.$router.push("/product");
    },
    selectTags() {
      this.status = "selectTag";
    },
    async createInquiry() {
      let productId = this.productId;
      let vendorId = this.newProduct.vendorId;
      await ProductService.createInquiry(productId, vendorId).then((res) => {
        if (res.message == "success") {
          this.showProducts();
        }
      });
    },
    showUpdateForm() {
      this.isCorrect = false;
      this.formLoader = true;
    },
    handleStatusChange(newStatus) {
      this.status = newStatus;
    },
  },
};
</script>
