<template>
  <v-container>
    <!-- Delete Dialog -->
    <v-layout>
      <v-row>
        <v-dialog v-model="deleteDialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Delete Confirmation</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <p>Are you sure you want to delete this vendor?</p>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="$emit('delete', selectedId)"> Yes </v-btn>
              <v-btn text @click="deleteDialog = false"> No </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-layout>

    <!-- Active Confirmation -->
    <v-layout>
      <v-row>
        <v-dialog v-model="activateDialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Active Confirmation</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <p>Are you sure you want to activate this vendor?</p>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="$emit('activateVendor', selectedId)">
                Yes
              </v-btn>
              <v-btn text @click="activateDialog = false"> No </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-layout>

    <!-- Change Password Confirmation -->
    <v-layout>
      <v-row>
        <v-dialog v-model="changePasswordDialog" persistent max-width="600px">
        <v-form ref="changePasswordForm" v-model="valid">
          <v-card>
            <v-card-title>
              <span class="text-h5">Change Password Confirmation</span>
            </v-card-title>
            <v-card-text>
              <v-container>
               <v-row>
                  <p>
                    Are you sure you want to change password for this vendor?
                  </p>
                </v-row>
              <v-row>
                <v-col cols="12" md="8">
                  <v-text-field
                  type="password"
                  required
                  :rules="newPasswordRules"
                    v-model="changePasswordDTO.newPassword"
                    label="New Password"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="8">
                  <v-text-field
                  type="password"
                  required
                  :rules="confirmPasswordRules"
                    v-model="changePasswordDTO.confirmNewPassword"
                    label="Confirm New Password"
                  ></v-text-field>
                </v-col>
              </v-row>
               
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="validation()">
                Yes
              </v-btn>
              <v-btn text @click="changePasswordDialog = false"> No </v-btn>
            </v-card-actions>
          </v-card>
          </v-form>
        </v-dialog>
      </v-row>
    </v-layout>

    <v-row>
      <v-col cols="12">
        <h2 id="text">{{ title }}</h2>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col
        cols="12"
        md="3"
        sm="12"
        class="d-flex justify-md-end justify-sm-center"
      >
        <v-text-field
          v-model="search"
          solo
          prepend-inner-icon="mdi-magnify"
          placeholder="Search"
          style="width: 80%"
          id="search"
        >
        </v-text-field>
      </v-col>
      <v-spacer></v-spacer>
      <v-col
        cols="12"
        md="3"
        sm="12"
        class="d-flex justify-md-end justify-sm-center"
      >
        <v-btn id="button" tile @click="create" width="60%">
          <v-icon left>mdi-plus</v-icon>
          {{ buttonTitle }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :search="search"
          :headers="headers"
          :items="tableItems"
          item-key="id"
          class="elevation-1"
          :loading="loader"
        >
          <template v-slot:item.statusValue="{ item }">
            <v-chip
              id="chip"
              :class="item.statusValue"
              class="font-weight-bold"
              >{{ item.statusValue }}</v-chip
            >
          </template>
          <template v-slot:item.action="{ item }">
            <v-row class="d-flex justify-center">
              <!-- Edit Button -->
              <v-col
                cols="12"
                md="3"
                v-if="editButton"
                class="d-flex justify-center"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      :disabled="!item.active"
                      elevation="0"
                      @click="$emit('showEditDialog', item.id)"
                      >mdi-pencil</v-icon
                    >
                  </template>
                  <span>Edit</span>
                </v-tooltip>
              </v-col>

              <!-- Change Password Button -->
              <v-col
                cols="12"
                md="3"
                v-if="changePasswordButton"
                class="d-flex justify-center"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      elevation="0"
                      @click="showChangePasswordDialog(item.id)"
                      :disabled="!item.active"
                      >mdi-key-variant
                    </v-icon>
                  </template>
                  <span>Change Password</span>
                </v-tooltip>
              </v-col>

              <!-- Delete or Activate Button -->
              <v-col
                cols="12"
                md="3"
                v-if="deleteButton"
                class="d-flex justify-center"
              >
                <v-tooltip bottom v-if="item.active">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      elevation="0"
                      @click="showDeleteDialog(item.id)"
                      >mdi-delete
                    </v-icon>
                  </template>
                  <span>Delete</span>
                </v-tooltip>

                <v-tooltip bottom v-else>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      v-if="!item.active"
                      elevation="0"
                      @click="showActivateDialog(item.id)"
                      >mdi-progress-pencil</v-icon
                    >
                  </template>
                  <span>Activate Vendor</span>
                </v-tooltip>
              </v-col>
              <!-- <v-col cols="12" md="3" v-if="viewDetails" class="d-flex justify-start">
                <v-icon
                  elevation="0"
                  @click="$emit('viewShopDetails', item.id);"
                  >mdi-eye</v-icon
                >
              </v-col> -->
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    create: { type: Function },
    title: String,
    buttonTitle: String,
    headers: Array,
    tableItems: Array,
    chipStatus: String,
    loader: Boolean,
    viewDetails: Boolean,
    deleteButton: Boolean,
    editButton: Boolean,
    changePasswordButton: Boolean,
    disableEdit: Boolean,
  },
  data: () => ({
    newPasswordRules: [(v) => !!v || "New Password is required"],
    confirmPasswordRules: [(v) => !!v || "Confirm Password is required"],
    changePasswordDTO:{
      newPassword:'',
      confirmNewPassword:''
    },
    search: "",
    valid: true,
    errorMessage: false,
    activateDialog: false,
    deleteDialog: false,
    changePasswordDialog: false,
    selectedId: null,
  }),
  methods: {
  validation() {
     this.confirmPasswordRules = [
        (v) => !!v || "Confirm Password is required",
        (v) => v === this.changePasswordDTO.newPassword || "Passwords do not match"
      ];
     if(this.$refs.changePasswordForm.validate()){
         this.$emit('changeDirectPassword', {id:this.selectedId,changePassword: {newPassword:this.changePasswordDTO.newPassword}})
     }
     
    },
    cancel() {
      this.$refs.form.reset();
      this.props.dialog = false;
    },
    showDeleteDialog(id) {
      this.selectedId = id;
      this.deleteDialog = true;
    },
    showActivateDialog(id) {
      this.selectedId = id;
      this.activateDialog = true;
    },
    showChangePasswordDialog(id) {
      this.selectedId = id;
      this.changePasswordDialog = true;
    },
  },
};
</script>