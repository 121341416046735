<template>
    <v-container class="my-10">
      <v-dialog v-model="loading" fullscreen>
        <v-container
          fluid
          fill-height
          style="background-color: rgba(255, 255, 255, 0.5)"
        >
          <v-layout justify-center align-center>
            <v-progress-circular indeterminate color="primary">
            </v-progress-circular>
          </v-layout>
        </v-container>
      </v-dialog>
  
      <v-snackbar v-model="successSnackbar" absolute bottom color="success" text>
        {{ successMessage }}
  
        <template v-slot:action="{ attrs }">
          <v-btn
            color="success"
            text
            v-bind="attrs"
            @click="successSnackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
  
      <v-snackbar
        v-model="errorSnackbar"
        absolute
        bottom
        color="red accent-2"
        text
      >
        {{ errorMessage }}
  
        <template v-slot:action="{ attrs }">
          <v-btn color="red" text v-bind="attrs" @click="errorSnackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
  
     
      <v-card>
        <v-card-title>Setting</v-card-title>
        <v-card-text style="width: 100%">
          <v-form ref="form" v-model="valid">
            
            
           
            <v-row>
                <v-col cols="12" md="3">
  <v-checkbox
    v-model="setting.isForceToUpdate"
    label="App Force To Update"
  ></v-checkbox>
</v-col>
</v-row>
 
<v-row>
              
<v-col cols="12" md="3">
                  <v-text-field
                    v-model="setting.appVersion"
                    label="App Version"
                  ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="2">
                <v-btn @click="validation()" id="button" :disabled="isDisabled"
                  >Submit</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-container>
  </template>
  
  <script>
  import SettingService from "../../services/SettingService";
  
  export default {
    data: () => ({
      setting: {
        isForceToUpdate:false,
        appVersion:''
      },
      loader: false,
      valid: false,
      errorMessage: "",
      createFailed: false,
      isError: false,
      loading: false,
      isDisabled: false,
      showPassword: false,
      errorSnackbar: false,
      successMessage: "",
      successSnackbar: "",
    }),
    async created() {
        await this.fetchSetting();
    },
    methods: {
      async fetchSetting(){
        var setting = await SettingService.fetchSetting();
        this.setting.isForceToUpdate = setting ? setting.isForceToUpdate :  false;
        this.setting.appVersion = setting? setting.appVersion: '';
      },  
      validation() {
        this.loading = true;
        if (this.$refs.form.validate()) {
          this.valid = true;
          this.isDisabled = true;
          this.createSetting();
        } else {
          this.valid = false;
        }
      },
      async createSetting() {
        let requiredObj = this.setting;
        await SettingService.createOrUpdateSetting(requiredObj).then(async (res) => {
         if (res.message == "success") {
          this.successSnackbar = true;
          this.successMessage = "Successfully updated settings.";
          await this.fetchSetting();
            }
          this.loading = false;
          this.isDisabled = false;
        });
      },
      showCreatePage() {
        this.$router.push("/vendors");
      },
    },
  };
  </script>