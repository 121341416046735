<template>
  <v-container class="my-10">
    <v-dialog v-model="deleteDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Delete Confirmation</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <p>Are you sure you want to delete this task?</p>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="deleteProduct()"> Yes </v-btn>
          <v-btn text @click="deleteDialog = false"> No </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog flat v-model="editDialog" width="75%">
      <product-edit-form :selectedProduct="selectedProduct" :cancel="cancel"></product-edit-form>
    </v-dialog>

    <v-row align="center">
      <v-col cols="12" md="4" sm="12" class="d-flex justify-md-start justify-sm-center">
        <h2 id="text">Products</h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="2">
        <v-select dense outlined :items="vendors" item-text="name" item-value="id" placeholder="Choose Vendor"
          v-model="selectedVendorId" @change="getProductRecord"></v-select>
      </v-col>
      <v-col cols="12" md="3">
        <v-select dense outlined :items="genders" item-text="name" item-value="value" placeholder="Choose Gender"
          v-model="selectedProductFilter.gender" @change="genderTrigger"></v-select>
      </v-col>
      <v-col cols="12" md="3">
        <v-select dense outlined :items="categories" item-text="title" item-value="title" placeholder="Choose Category"
          v-model="selectedProductFilter.category" @change="categoryTrigger" ></v-select>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" md="4" sm="12" class="d-flex justify-md-end justify-sm-center">
        <v-btn id="button" tile @click="createPage" width="60%">
          <v-icon left>mdi-plus</v-icon>
          Create Product
        </v-btn>
      </v-col>
    </v-row>

    <!-- Product Card -->
    <v-row>
      <v-col cols="12" sm="12" md="4" lg="3" v-for="product in products" :key="product.id">
        <!-- <v-hover v-slot="{ hover }"> -->
        <v-card class="mx-auto pa-0 rounded-lg" max-width="332px" height="420px" outlined
          @click="viewProductDetails(product.id)">
          <div>
            <v-img :src="`${product.photoUrl}`" style="height: 250px">
              <div class="align-self-right">
                <v-menu>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-list>
                      <v-list-item>
                        <v-btn text @click="showEditDialog(product.id)">Edit</v-btn>
                      </v-list-item>
                      <v-list-item>
                        <v-btn text @click="showDeleteDialog(product.id)">Delete</v-btn>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-menu>
              </div>
            </v-img>
          </div>
          <v-card-title>{{ product.name }}</v-card-title>
          <v-card-text class="text-pre-wrap">
            <div>
              {{ product.description }}
            </div>
            <div v-if="product.discountedPrice !== 0">
              <span class="text-h6" style="color: #008499">Rs. {{ product.discountedPrice }}</span>
              <div v-if="product.discount.amount !== 0">
                <span class="text-decoration-line-through">{{
                  product.price
                }}</span>
                <span v-if="product.discount.type == 'fixed'">
                  -Rs. {{ product.discount.amount }}</span>
                <span v-if="product.discount.type == 'percentage'">
                  -{{ product.discount.amount }}%</span>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProductService from "../../services/ProductService";
import VendorService from "../../services/VendorService";
import CategoryService from "../../services/CategoryService";

import ProductEditForm from "../../components/EditProductForm.vue";

export default {
  name: "Product",
  data: () => ({
    products: [],
    categories:[],
    genders:[{
      name:"Select none",
      value:null
    },{
      name:"Men",
      value:"men"
    },{
      name:"Women",
      value:"women"
    },
    {
      name:"Accessories",
      value:"all"
    }],
    vendors: [],
    individualProduct: {},
    selectedVendorId: null,
    selectedProductFilter: {
      category:null,
      keywords:null,
      gender: null,

    },
    editDialog: false,
    deleteDialog: false,
    valid: false,
    isDisabled: false,
    selectedId: null,
    selectedProduct: {},
    errorMessage: false,
  }),
  components: { ProductEditForm },
  watch: {
    selectedVendorId() {
      this.isDataLoaded = false;
      this.getProductRecord();
    },
  },
  created() {
    this.getVendors();
  },
  methods: {
    getVendors() {
      VendorService.fetchVendorRecord().then(
        (response) => {
          let requiredData = response;
          this.vendors = requiredData;
        }
      );
      this.selectedVendorId = JSON.parse(localStorage.getItem("selectedVendorId"));
    },
    saveVendor() {
      localStorage.setItem("selectedVendorId", this.selectedVendorId);
      window.dispatchEvent(
        new CustomEvent("selectedVendorId-localstorage-changed", {
          detail: {
            storage: localStorage.getItem("selectedVendorId"),
          },
        })
      );
    },
    genderTrigger(){
      this.getTagCategoryRecord();
      this.getProductRecord();
    },
    categoryTrigger(e){
      const selectedCategory = this.categories.find(category => category.title === e);
      this.selectedProductFilter.keywords= selectedCategory ?selectedCategory.keywords : null
      this.getProductRecord();
    },

    getProductRecord() {
      this.saveVendor();
      ProductService.fetchProductRecord(this.selectedVendorId,this.selectedProductFilter).then(
        (response) => {
          let requiredData = response;
          this.products = [];
          requiredData.map((item) => {
            if (item.vendorId == this.selectedVendorId) {
              // Calculate discounted price
              let discountedPrice;
              if (item.discount.type == "fixed") {
                discountedPrice = item.price - item.discount.amount;
              } else if (item.discount.type == "percentage") {
                discountedPrice =
                  item.price - item.discount.amount * 0.01 * item.price;
              }

              // Product Status
              if (item.status) {
                item = { statusValue: "Active", discountedPrice, ...item };
              } else {
                item = { statusValue: "Inactive", discountedPrice, ...item };
              }
              this.products.push(item);
            }
          });
          this.loader = false;
        }
      );
    },
    getTagCategoryRecord() {
      CategoryService.fetchTagCategories({category:this.selectedProductFilter.gender}).then(
        (response)=>{
          this.categories = [{ title: 'None', value: null }, ...response];
          
        })
      
    },
    createPage() {
      this.$router.push("/product/create");
    },
    viewProductDetails(id) {
      this.$router.push(`/vendor/${this.selectedVendorId}/product/${id}`);
    },
    showDeleteDialog(id) {
      this.selectedId = id;
      this.deleteDialog = true;
    },
    showEditDialog(id) {
      this.selectedId = id;
      this.selectedProduct = this.products.filter((product) => product.id == id)[0];
      this.editDialog = true;
    },
    async deleteProduct() {
      await ProductService.deleteProduct(this.selectedId, this.selectedVendorId).then(() => {
        this.getProductRecord();
      });
      this.deleteDialog = false;
    },
    cancel() {
      this.editDialog = false;
      this.deleteDialog = false;
    },
    validation() {
      if (this.$refs.form.validate()) {
        this.valid = true;
        (this.isDisabled = true), this.editProduct();
      } else {
        this.valid = false;
      }
    },
  },
};
</script>
