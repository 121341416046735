<template>
  <v-app>
    <v-main style="background: #212121">
      <v-container grid-list-xl>
        <v-row row class="mt-5">
          <v-card
            style="border-radius: 10px; margin-top: 5%"
            class="mx-auto"
            width="40%"
          >
            <v-card-title class="text-xs-right">
              <span class="headline text-xs-right">Khoji</span>
            </v-card-title>
            <v-card-text>
              <div class="text-xs-center">
                <v-alert
                  :value="true"
                  color="error"
                  transition="scale-transition"
                  icon="mdi-alert"
                  v-if="this.message"
                  outlined
                  class="text-xs-center"
                  style="border-radius: 5px"
                >
                  <b>{{ this.message }}</b>
                </v-alert>
              </div>
              <v-container grid-list-xl>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-row row wrap>
                    <v-col md12 xs12 class="pb-0" md="12" cols="12">
                      <v-text-field
                        v-model="email"
                        label="Email*"
                        type="email"
                        outlined
                        prepend-icon="mdi-account"
                        outline
                        required
                        placeholder="awesome@email.com"
                        :rules="emailRules"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md12 xs12 class="pb-0">
                      <v-text-field
                        v-model="password"
                        label="Password*"
                        outlined
                        outline
                        prepend-icon="mdi-lock"
                        required
                        placeholder="1-9/a-z/A-Z/!-+"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPassword ? 'text' : 'password'"
                        @click:append="showPassword = !showPassword"
                        :rules="passwordRules"
                        v-on:keyup.enter="accessLogin"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
                <small>*indicates required field</small>
                <div class="text-xs-right">
                  <v-btn
                    color="#c2ad76 "
                    block
                    style="
                      border-radius: 5px;
                      background: #008499;
                      color: #ffffff;
                    "
                    :disabled="!valid"
                    @click="validation()"
                    >Login</v-btn
                  >
                </div>
              </v-container>
            </v-card-text>
          </v-card>
        </v-row>
      </v-container>
    </v-main>

    <v-main v-if="login === 'Success'" style="background: rgb(245, 246, 250)">
      <app-header v-if="login === 'Success'"></app-header>
      <v-container fluid style="background: rgb(245, 246, 250)">
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import LoginService from "../services/LoginService";
export default {
  name: "Login",
  components: {},
  data: () => ({
    login: false,
    email: "",
    password: "",
    emailRules: [
      (v) => !!v || "Email is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    passwordRules: [(v) => !!v || "Password is required"],
    valid: false,
    errorSnackBar: false,
    showPassword: false,
    errorText: `Incorrect Password`,
    message: "",
  }),

  methods: {
    accessLogin() {
      this.message = "";
      let formObj = {
        email: this.email,
        password: this.password,
      };
      LoginService.handleLogin(formObj).then((res) => {
        if (res == "success") {
          this.$router.push("/overview");
        } else {
          this.errorSnackBar = true;
          this.message = "Incorrect Password";
        }
      });
    },
    validation() {
      if (this.$refs.form.validate()) {
        this.valid = true;
        this.accessLogin();
      } else {
        this.valid = false;
      }
    },
  },
};
</script>

<style>
.v-main .element-style {
  padding: 0px;
}
.main-background {
  background: rgb(5, 56, 107);
  background: linear-gradient(
    148deg,
    rgba(5, 56, 107, 1) 0%,
    rgba(55, 150, 131, 1) 62%,
    rgba(92, 219, 149, 1) 100%
  );
}
.main-title {
  font-family: "Lexend Deca", sans-serif;
  font-size: 1.5rem;
}
</style>