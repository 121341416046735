<template>
  <v-container class="my-10">
    <VendorList
      v-if="isDataLoaded"
      :title="title"
      :create="showDialog"
      :buttonTitle="buttonTitle"
      :headers="headers"
      :tableItems="vendors"
      :deleteButton="true"
      :editButton="true"
      :changePasswordButton="true"
      v-on:showEditDialog="showEditDialog"
      v-on:delete="deleteVendor"
      v-on:activateVendor="activateVendor"
      v-on:changePassword="changePassword"
      v-on:changeDirectPassword="changeDirectPassword"
    ></VendorList>

    <v-dialog flat v-model="editDialog" width="50%">
      <v-card>
        <v-layout>
          <v-flex wrap>
            <v-card>
              <v-card-title>
                <span class="headline">Edit Vendor</span>
                <v-col class="text-right">
                  <v-btn icon @click="cancel()">
                    <v-icon dark>mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-card-title>
              <v-divider></v-divider>
              <v-form ref="form">
                <v-card-text>
                  <v-container grid-list-md>
                    <v-layout wrap>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                          required
                          :rules="nameRules"
                          v-model="selectedVendor.name"
                          label="Name"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          required
                          :rules="addressRules"
                          v-model="selectedVendor.address"
                          label="Address"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          required
                          :rules="panRules"
                          v-model="selectedVendor.panNumber"
                          label="Pan Number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          required
                          :rules="websiteRules"
                          v-model="selectedVendor.website"
                          label="Website"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-file-input
                          @change="uploadLogo"
                          accept="image/*"
                          label="Logo"
                        ></v-file-input>
                      </v-col>
                      <v-col cols="12" md="6"></v-col>
                      <v-col cols="12" md="12">
                      <v-card elevation="0">
              <v-card-title>User Details</v-card-title>
            </v-card>
            </v-col>
                       <v-col cols="12" md="6">
              <v-text-field
                required
                :rules="nameRules"
                v-model="selectedVendor.firstName"
                label="First Name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                required
                :rules="nameRules"
                v-model="selectedVendor.lastName"
                label="Last Name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                required
                :rules="emailRules"
                v-model="selectedVendor.email"
                label="Email"
              ></v-text-field>
            </v-col>
             <v-col cols="12" md="6">
              <v-text-field
                required
                :rules="phoneRules"
                v-model="selectedVendor.phone"
                label="Phone"
              ></v-text-field>
            </v-col>
                      </v-row>
                      
                    </v-layout>
                  </v-container>
                  <v-divider></v-divider>
                  <br />
                  <v-col class="text-right">
                    <v-btn
                      dense
                      id="button"
                      @click="validation()"
                      :disabled="isDisabled"
                      >Save</v-btn
                    >
                    <v-btn dense id="button" class="ml-4" @click="cancel()"
                      >Cancel</v-btn
                    >
                  </v-col>
                </v-card-text>
              </v-form>
            </v-card>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import VendorService from "../../services/VendorService";
import VendorList from "../../components/DataListPage.vue";
export default {
  name: "Vendor",
  data: () => ({
    headers: [
      { text: "Id", align: "left", sortable: true, value: "id" },
      { text: "Vendor Name", align: "left", sortable: true, value: "name" },
      { text: "Address", align: "left", sortable: true, value: "address" },
      { text: "Pan Number", align: "left", sortable: true, value: "panNumber" },
      { text: "Website", align: "left", sortable: true, value: "website" },
      { text: "Status", align: "left", sortable: true, value: "statusValue" },
      { text: "Actions", align: "center", sortable: true, value: "action" },
    ],
    title: "Vendor Overview",
    buttonTitle: "Create Vendor",
    vendors: [],
    newVendor: {
      name: "",
      address: "",
      panNumber: null,
      website: "",
      userEmail: "",
    },
    selectedVendor: {
      name: "",
      address: "",
      panNumber: "",
      website: "",
      logo: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
    },
    addVendorDialog: false,
    idRules: [(v) => !!v || "Id is required"],
    nameRules: [(v) => !!v || "Name is required"],
    addressRules: [(v) => !!v || "Address is required"],
    emailRules: [
      (v) => !!v || "Email is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    panRules: [(v) => !!v || "Pan Number is required"],
    websiteRules: [(v) => !!v || "Website is required"],
    phoneRules: [(v) => !!v || "Phone is required"],
    errorMessage: false,
    loader: true,
    editDialog: false,
    isDisabled: false,
    selectedVendorId: null,
    isDataLoaded: false,
  }),
  components: { VendorList },
  created() {
    this.getVendorRecord();
  },
  beforeCreate() {
    if (!localStorage.getItem("token")) {
      this.$router.push("/");
    }
  },
  methods: {
    async getVendorRecord() {
      await VendorService.fetchVendorRecord().then((response) => {
        let requiredData = response;
        this.vendors = requiredData.map((item) => {
          let statusValue = item.active == true ? "Active" : "Inactive";
          return { ...item, statusValue };
        });
        this.isDataLoaded = true;
      });
    },
    cancel() {
      this.$refs.form.reset();
      this.editDialog = false;
    },
    async editVendor() {
      let requiredObj = this.selectedVendor;
      await VendorService.editVendor(requiredObj, this.selectedVendorId).then(
        (res) => {
          res; // eslint-disable-line no-unused-vars
          window.location.reload();
        }
      );
    },
    async activateVendor(id) {
      await VendorService.deleteVendor(id).then(() => {
        window.location.reload();
      });
    },
    async changePassword(id) {
      await VendorService.changePassword(id).then((res) => {
        if (res == "success") {
          window.location.reload();
        }
      });
    },
    async changeDirectPassword({id,changePassword}){
    await VendorService.changeDirectPassword(id,changePassword).then((res) => {
        if (res == "success") {
          window.location.reload();
        }
      });
    },
    showDialog() {
      this.$router.push("/vendor/create");
    },
    showEditDialog(id) {
      let selectedVendor = this.vendors.filter((vendor) => vendor.id == id);
      this.selectedVendorId = selectedVendor[0].id;
      this.selectedVendor.name = selectedVendor[0].name;
      this.selectedVendor.address = selectedVendor[0].address;
      this.selectedVendor.panNumber = selectedVendor[0].panNumber;
      this.selectedVendor.website = selectedVendor[0].website;
      this.selectedVendor.logo = selectedVendor[0].logo;
      this.selectedVendor.firstName = selectedVendor[0].vendorUsers[0].firstName;
      this.selectedVendor.lastName = selectedVendor[0].vendorUsers[0].lastName;
      this.selectedVendor.email = selectedVendor[0].vendorUsers[0].email;
      this.selectedVendor.phone = selectedVendor[0].vendorUsers[0].phone;
      this.editDialog = true;
    },
    validation() {
      this.isDisabled = false;
      if (this.$refs.form.validate()) {
        (this.isDisabled = true), this.editVendor();
      }
     
    },
    async deleteVendor(id) {
      await VendorService.deleteVendor(id).then(() => {
        // this.getVendorRecord();
        window.location.reload();
      });
    },
    viewVendorDetails(id) {
      this.$router.push(`/vendor/id=${id}`);
    },
    uploadLogo(image) {
      this.selectedVendor.logo = image;
    },
  },
};
</script>
