import { objectWithoutNulls } from "../utils/array-utils";
import APIManager from "./APIManager";

const fetchProductRecord = async (vendorId,params) => {
  const products = await APIManager.axios
    .post(`/v1/admin/vendor/${vendorId}/product/filter`,objectWithoutNulls(params))
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      return err.response.data;
    });

  return products;
};


const fetchIndividualProduct = async (productId, vendorId) => {
  const product = await APIManager.axios
    .get(`/v1/admin/vendor/${vendorId}/product/${productId}`)
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      return err.response.data;
    });

  return product;
};

const createProduct = async (requiredObj) => {
  let formData = new FormData();
  formData.append("name", requiredObj.name);
  formData.append("description", requiredObj.description);
  formData.append("price", requiredObj.price);
  formData.append("primaryImage", requiredObj.primaryImage);
  formData.append("sizes", requiredObj.sizes);
  requiredObj.images.map((e) => {
    formData.append("images", e);
  });

  const response = await APIManager.axios
    .post(`/v1/admin/vendor/${requiredObj.vendorId}/product`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
        return response.data;
    })
    .catch((err) => {
      if (typeof err.response.data.message !== "undefined") {
        return err.response.data.message;
      } else {
        return "Network error";
      }
    });
  return response;
};

const deleteProduct = async (id, vendorId) => {
  const response = await APIManager.axios
    .delete(`/v1/admin/vendor/${vendorId}/product/${id}`)
    .then((res) => {
      return res.data.message;
    })
    .catch((err) => {
      return err.response.data;
    });
  return response;
};

const editProduct = async (reqObj, product) => {
  const productDetails = await APIManager.axios
      .patch(`/v1/admin/vendor/${reqObj.vendorId}/product/${reqObj.productId}`, product)
      .then(
          response => {
              return response.data;

          },
      )
      .catch((err) => {
          return err.response.data
      })
  return productDetails;
}

const createInquiry = async (productId, vendorId) =>{
  const response = await APIManager.axios.post(`/v1/admin/vendor/${vendorId}/product/${productId}/inquiry`).then((res)=>{
    return res.data;
  }).catch((err)=>{
    return err.response.data;
  })

  return response;
}

export default {
  fetchProductRecord,
  fetchIndividualProduct,
  createProduct,
  deleteProduct,
  editProduct,
  createInquiry
};
