import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { app as firebaseApp, analytics } from './firebase';

Vue.config.productionTip = false;

Vue.prototype.$firebaseApp = firebaseApp;
Vue.prototype.$analytics = analytics;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');