<template>
  <v-container class="my-10">
    <v-dialog v-model="loading" fullscreen>
      <v-container
        fluid
        fill-height
        style="background-color: rgba(255, 255, 255, 0.5)"
      >
        <v-layout justify-center align-center>
          <v-progress-circular indeterminate color="primary">
          </v-progress-circular>
        </v-layout>
      </v-container>
    </v-dialog>

    <v-snackbar v-model="successSnackbar" absolute bottom color="success" text>
      {{ userCreateMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="success"
          text
          v-bind="attrs"
          @click="successSnackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="errorSnackbar"
      absolute
      bottom
      color="red accent-2"
      text
    >
      {{ errorMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="errorSnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <!-- <v-row class="d-flex align-center justify-center" v-if="createFailed">
      <v-col cols="12" md="4">
        <v-dialog v-model="isError" width="20%">
          <v-card>
            <v-card-title class="d-flex align-center justify-center">
              <div>
                <v-icon large color="#c7ae78">mdi-alert-circle-outline</v-icon>
              </div>
            </v-card-title>
            <v-card-text class="d-flex align-center justify-center">
              <div>
                {{ errorMessage }}
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions
              class="d-flex align-center justify-center"
              style="background-color: #c7ae78"
            >
              <span @click="isError = false"><v-btn text>Dismiss</v-btn></span>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row> -->
    <v-card>
      <v-card-title>Create Vendor</v-card-title>
      <v-card-text style="width: 100%">
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field
                required
                :rules="nameRules"
                v-model="newVendor.name"
                label="Name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                required
                :rules="addressRules"
                v-model="newVendor.address"
                label="Address"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field
                required
                :rules="panRules"
                v-model="newVendor.panNumber"
                label="Pan Number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                required
                :rules="websiteRules"
                v-model="newVendor.website"
                label="Website"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <v-file-input
                @change="uploadLogo"
                accept="image/*"
                label="Logo"
              ></v-file-input>
            </v-col>
          </v-row>
          <v-row class="pa-0">
            <v-card elevation="0">
              <v-card-title>User Details</v-card-title>
            </v-card>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field
                required
                :rules="nameRules"
                v-model="newVendor.firstName"
                label="First Name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                required
                :rules="nameRules"
                v-model="newVendor.lastName"
                label="Last Name"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field
                required
                :rules="emailRules"
                v-model="newVendor.email"
                label="Email"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                required
                :rules="passwordRules"
                v-model="newVendor.password"
                label="Password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field
                required
                :rules="phoneRules"
                v-model="newVendor.phone"
                label="Phone"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="2">
              <v-btn @click="validation()" id="button" :disabled="isDisabled"
                >Submit</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import VendorService from "../../services/VendorService";

export default {
  data: () => ({
    newVendor: {
      name: "",
      address: "",
      panNumber: null,
      website: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      phone: "",
      logo: ""
    },
    loader: false,
    valid: false,
    errorMessage: "",
    nameRules: [(v) => !!v || "Name is required"],
    addressRules: [(v) => !!v || "Address is required"],
    emailRules: [
      (v) => !!v || "Email is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    panRules: [(v) => !!v || "Pan Number is required"],
    websiteRules: [(v) => !!v || "Website is required"],
    passwordRules: [(v) => !!v || "Password is required"],
    phoneRules: [(v) => !!v || "Phone is required"],
    createFailed: false,
    isError: false,
    loading: false,
    isDisabled: false,
    showPassword: false,
    errorSnackbar: false,
    userCreateMessage: "",
    successSnackbar: "",
  }),
  created() {},
  methods: {
    validation() {
      this.loading = true;
      if (this.$refs.form.validate()) {
        this.valid = true;
        this.isDisabled = true;
        this.createVendor();
      } else {
        this.valid = false;
      }
    },
    uploadLogo(image) {
      this.newVendor.logo = image;
    },
    async createVendor() {
      let requiredObj = this.newVendor;
      await VendorService.createVendor(requiredObj).then((res) => {
        if (res.message == "success") {
          if (!res.data.userCreated) {
            this.userCreateMessage =
              "The vendor has been created for your existing user account";
          } else {
            this.userCreateMessage = `Vendor created for user ${res.data.user.firstName} ${res.data.user.lastName}`;
          }
          this.loading = false;
          this.successSnackbar = true;
        } else {
          this.loading = false;
          this.errorMessage = "Could not create vendor";
          this.errorSnackbar = true;
        }
        this.isDisabled = false;
        this.$refs.form.reset();
      });
    },
    showCreatePage() {
      this.$router.push("/vendors");
    },
  },
};
</script>