import APIManager from "./APIManager";

const fetchSetting = async () => {
  const productTags = await APIManager.axios
    .get(`/v1/admin/setting`)
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      return err.response.data;
    });

  return productTags;
};
const createOrUpdateSetting = async (requiredObj) => {
    
    const response = await APIManager.axios.post("/v1/admin/setting", requiredObj, {
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      return res.data;
    }).catch((err) => {
      return err.response.data;
    });
    return response;
  };
export default {
    fetchSetting,
    createOrUpdateSetting
   };