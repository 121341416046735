import APIManager from "./APIManager";

const fetchProductTag = async () => {
  const productTags = await APIManager.axios
    .get(`/v1/admin/product-tag`)
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      return err.response.data;
    });

  return productTags;
};

const editProductTag = async (reqObj) => {
  const productTags = await APIManager.axios
    .patch(`/v1/admin/product-tag`, reqObj)
    .then(
      response => {
        return response.data;

      },
    )
    .catch((err) => {
      return err.response.data
    })
  return productTags;
}

const editTagVisibility = async (selectedTags, productId) => {
  const productTags = await APIManager.axios
    .patch(`/v1/admin/product-tag/update/visibility?productId=${productId}`, {
      selectedTags
    })
    .then(
      response => {
        return response.data;

      },
    )
    .catch((err) => {
      return err.response.data
    })
  return productTags;
}

export default {
  fetchProductTag,
  editProductTag,
  editTagVisibility
};