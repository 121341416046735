import APIManager from "./APIManager";

const fetchTagCategories = async (params) => {
  const products = await APIManager.axios
    .get(`/v1/admin/category/tag`,{params})
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      return err.response.data;
    });

  return products;
};
export default {
    fetchTagCategories
}