import APIManager from "./APIManager";

const fetchInquiry = async () => {
  const inquirys = await APIManager.axios
    .get(`/v1/admin/inquiry`)
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      return err.response.data;
    });

  return inquirys;
};

const editInquiry = async (id) => {
  const inquiryDetails = await APIManager.axios
    .patch(`/v1/admin/inquiry/${id}`)
    .then(
      response => {
        return response.data;

      },
    )
    .catch((err) => {
      return err.response.data
    })
  return inquiryDetails;
}

export default {
  fetchInquiry,
  editInquiry
};