import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Vendor from '../views/Vendor/Vendor.vue'
import CreateVendor from '../views/Vendor/CreateVendor.vue';
import ProductList from '../views/Product/ProductList.vue';
import CreateProduct from '../views/Product/CreateProduct.vue';
import ProductDetails from '../views/Product/ProductDetails.vue';
import Overview from '../views/Overview.vue';
import Setting from '../views/Setting/CreateOrUpdateSetting.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      hideNavbar: true,
    },
  },
  {
    path: '/vendor',
    name: 'Vendor',
    component: Vendor
  },
  {
    path: '/vendor/create',
    name: 'CreateVendor',
    component: CreateVendor
  },
  {
    path: '/products',
    name: 'ProductList',
    component: ProductList
  },
  {
    path: '/product/create',
    name: 'CreateProduct',
    component: CreateProduct
  },
  {
    path: '/vendor/:vendorId/product/:id',
    name: 'ProductDetails',
    component: ProductDetails
  },
  {
    path: '/overview',
    name: 'Overview',
    component: Overview
  },
  {
    path: '/setting',
    name: 'Setting',
    component: Setting
  }
]

const router = new VueRouter({
  mode: "history",
  routes
})

export default router
