import APIManager from "./APIManager";

const fetchOverviewRecord = async () => {
  const overview = await APIManager.axios
    .get(`/v1/admin/overview`)
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      return err.response.data;
    });
  return overview;
};
const fetchEventsRecord = async (params) => {
  const overview = await APIManager.axios
    .get(`/v1/admin/overview/events`,{params})
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      return err.response.data;
    });
  return overview;
};

export default{ fetchOverviewRecord,fetchEventsRecord }