import APIManager from "./APIManager";

const fetchVendorRecord = async () => {
  const vendors = await APIManager.axios
    .get(`/v1/admin/vendor`)
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      return err.response.data;
    });

  return vendors;
};

const fetchIndividualVendor = async (id) => {
  const vendor = await APIManager.axios
    .get(`/v1/admin/vendor/${id}`)
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      return err.response.data;
    });

  return vendor;
};

const createVendor = async (requiredObj) => {
  let formData = new FormData();
  formData.append("name", requiredObj.name);
  formData.append("address", requiredObj.address);
  formData.append("panNumber", requiredObj.panNumber);
  formData.append("website", requiredObj.website);
  formData.append("firstName", requiredObj.firstName);
  formData.append("lastName", requiredObj.lastName);
  formData.append("email", requiredObj.email);
  formData.append("password", requiredObj.password);
  formData.append("phone", requiredObj.phone);
  formData.append("logo", requiredObj.logo);

  const response = await APIManager.axios.post("/v1/admin/vendor", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }).then((res) => {
    return res.data;
  }).catch((err) => {
    return err.response.data;
  });
  return response;
};

const editVendor = async (reqObj, vendorId) => {
  let formData = new FormData();
  formData.append("name", reqObj.name);
  formData.append("address", reqObj.address);
  formData.append("panNumber", reqObj.panNumber);
  formData.append("website", reqObj.website);
  formData.append("logo", reqObj.logo);
  formData.append("active", reqObj.active);
  formData.append("firstName",reqObj.firstName);
  formData.append("lastName",reqObj.lastName);
  formData.append("email",reqObj.email);
  formData.append("phone",reqObj.phone);

  const vendorDetails = await APIManager.axios
    .patch(`/v1/admin/vendor/${vendorId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(
      response => {
        return response.data;

      },
    )
    .catch((err) => {
      return err.response.data
    })
  return vendorDetails;
};

const deleteVendor = async (id) => {
  const response = await APIManager.axios.delete(`/v1/admin/vendor/${id}`).then((res) => {
    return res.data.message;
  }).catch((err) => {
    return err.response.data;
  });
  return response;
};

const changePassword = async (id) => {
  const response = await APIManager.axios.patch(`/v1/admin/vendor/change-password`, {
    vendorId: id
  }).then((res) => {
    return res.data.message;
  }).catch((err) => {
    return err.response.data;
  });
  return response;
};
const changeDirectPassword = async (id,body) => {
  const response = await APIManager.axios.patch(`/v1/admin/vendor/${id}/change-direct-password`, {
    ...body
  }).then((res) => {
    return res.data.message;
  }).catch((err) => {
    return err.response.data;
  });
  return response;
};

export default {
  fetchVendorRecord,
  fetchIndividualVendor,
  createVendor,
  editVendor,
  deleteVendor,
  changePassword,
  changeDirectPassword
};
