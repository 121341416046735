<template>
	<v-card>
		<v-layout>
			<v-flex wrap>
				<v-card>
					<v-card-title>
						<span class="headline">Edit Product</span>
						<v-col class="text-right">
							<v-btn icon @click="cancel()">
								<v-icon dark>mdi-close</v-icon>
							</v-btn>
						</v-col>
					</v-card-title>
					<v-divider></v-divider>
					<v-form ref="form" v-model="valid">
						<v-card-text>
							<v-container grid-list-md>
								<v-layout wrap>
									<v-col cols="12">
										<v-text-field required :rules="nameRules" v-model="selectedProduct.name" label="Name"></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-textarea required :rules="descriptionRules" v-model="selectedProduct.description"
											label="Description" auto-grow rows="3"></v-textarea>
									</v-col>
									<v-col cols="12">
										<v-text-field required :rules="priceRules" v-model="selectedProduct.price"
											label="Price"></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field v-model="selectedProduct.sizes"
											label="Sizes"></v-text-field>
									</v-col>
									<v-col cols="6">
										<v-select :items="topCategory" label="Top Category" v-model="selectedTag.topCategory"
											@change="changeTopCategory()"></v-select>
									</v-col>
									<v-col cols="6">
										<v-select :items="categories" label="Category" v-model="selectedTag.category"
											@change="tagsChanged = true"></v-select>
									</v-col>
									<v-col cols="4" v-for="item in features" :key="item.key">
										<v-select :items="item.value" :label="item.key" v-model="selectedTag.features[item.key]"
											@change="tagsChanged = true"></v-select>
									</v-col>
								</v-layout>
							</v-container>
							<v-divider></v-divider>
							<br />
							<v-col class="text-right">
								<v-btn dense id="button" @click="validation()">Save</v-btn>
								<v-btn dense id="button" class="ml-4" @click="cancel()">Cancel</v-btn>
							</v-col>
						</v-card-text>
					</v-form>
				</v-card>
			</v-flex>
		</v-layout>
	</v-card>
</template>

<script>
import ProductService from "../services/ProductService";
import ProductTagService from "../services/ProductTagService";

export default {
	name: "ProductEditForm",
	props: { selectedProduct: Object, cancel: Function },
	data: () => ({
		productId: "",
		selectedVendor: "",
		selectedTag: {
			topCategory: null,
			category: null,
			features: null
		},
		features: [],
		subCategories: [],
		topCategory: [],
		categories: [],
		nameRules: [(v) => !!v || "Name is required"],
		descriptionRules: [
			(v) => !!v || "Description is required",
			(v) => v.length <= 250 || "Max 250 characters",
		],
		priceRules: [(v) => !!v || "Price is required"],
		formLoader: false,
		tagsChanged: false,
    valid: false,
	}),
	watch: {
		"selectedProduct.id" () {
			this.productId = this.selectedProduct.id;
			this.getProductTags();
		},
		"selectedTag.topCategory"(item) {
			this.getCategories(item);
		},
		"selectedTag.category"(category) {
			this.getFeatures(this.selectedTag.topCategory, category);
		},
	},
	mounted() {
		window.addEventListener("selectedVendorId-localstorage-changed", (event) => {
			this.selectedVendor = event.detail.storage;
		});
	},
	created() {
		if (!this.selectedVendor) {
			this.selectedVendor = JSON.parse(localStorage.getItem("selectedVendorId"));
		}
		this.productId = this.selectedProduct.id;
		this.getProductTags();
	},
	methods: {
		changeTopCategory() {
			this.selectedTag.category = "";
			this.selectedTag.features = {};
			this.tagsChanged = true;
		},
		async getProductTags() {
      this.topCategory = [];
			await ProductTagService.fetchProductTag().then((response) => {
				this.productTags = response;
				for (var topCategory in this.productTags) {
					this.topCategory.push(topCategory);
				}
			});
			this.getProductDetails();
		},
		getCategories(topCategory) {
			this.features = [];
			this.categories = [];
			this.subCategories = [];
			for (var category in this.productTags[topCategory]) {
				this.categories.push(category);
			}
		},
		getFeatures(topCategory, category) {
			this.features = [];
			for (var feature in this.productTags[topCategory][category]) {
				this.features.push({
					key: feature,
					value: this.productTags[topCategory][category][feature],
				});
			}
		},
		async getProductDetails() {
			await ProductService.fetchIndividualProduct(this.productId, this.selectedVendor).then((response) => {
				let tags = { category: "", topCategory: "", features: {}, subCategory: "" };
				response.ProductTags.map((tag) => {
					if (tag.type == "feature" || tag.type == 'subcategory') {
						tags.features[tag.name] = tag.ProductTag.name;
					} else if (tag.type == "category" && tag.name == "Category") {
						tags.category = tag.ProductTag.name;
					} else if (tag.type == "category" && tag.name == "Top Category") {
						tags.topCategory = tag.ProductTag.name;
					}
				});
				if (tags.topCategory == "Top Category") {
					try {
						this.selectedTag.topCategory = tags.category.split("/")[0];
					} catch (e) {
						this.selectedTag.topCategory = ""
					}
				} else {
					this.selectedTag.topCategory = tags.topCategory;
				}
				this.selectedTag.category = tags.category;
				this.selectedTag.features = tags.features;
				this.selectedTag.subCategory = tags.subCategory;
			});
			this.formLoader = false;
		},
		validation() {
			if (this.$refs.form.validate()) {
				this.valid = true;
				this.editProduct();
			} else {
				this.valid = false;
			}
		},
		editProduct() {
			let reqObj = { productId: this.selectedProduct.id, vendorId: this.selectedVendor }
			if(this.tagsChanged) {
				console.log("tags changes")
				let tags = {};
				tags.Category = this.selectedTag.category;
				tags.topCategory = this.selectedTag.topCategory;
	
				for (var feature in this.selectedTag.features) {
					tags[feature] = this.selectedTag.features[feature];
				}
				let tagsObj = {
					productId: this.productId,
					tags
				}
				this.selectedProduct["productTags"] = tagsObj
				this.tagsChanged = false;
			}
			ProductService.editProduct(reqObj, this.selectedProduct).then(
				() => {
					window.location.reload();
				}
			);
			this.cancel();
		},
	},
};
</script>