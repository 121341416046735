<template><v-card>
    <v-card-title class="d-flex justify-center">Khoji</v-card-title>
    <v-card-text>
        <v-row>
        <v-col cols="12" md="4">
          <v-menu
            v-model="startDatePicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-bind="attrs"
                v-on="on"
                v-model="params.startDate"
                label="Start Date"
                prepend-icon="mdi-calendar"
                readonly
              ></v-text-field>
            </template>
            <v-date-picker 
              v-model="params.startDate" 
              @input="startDatePicker = false"
              :max="params.endDate" 
            ></v-date-picker>
          </v-menu>
          
        </v-col>
        <v-col cols="12" md="4">
          <v-menu
            v-model="endDatePicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-bind="attrs"
                v-on="on"
                v-model="params.endDate"
                label="End Date"
                prepend-icon="mdi-calendar"
                readonly
              ></v-text-field>
            </template>
            <v-date-picker 
              v-model="params.endDate" 
              @input="endDatePicker = false"
              :min="params.startDate" 
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="4" >
            <v-btn id="button" tile @click="emitEvents"  width="60%">
          <v-icon left>mdi-plus</v-icon>
          Filter
        </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="data"
        class="elevation-1"
      ></v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
    props: {
    headers: Array,
    data: Array,
    params: {
      type: Object,
      required: true,
      default: () => ({
        startDate: '',
        endDate: ''
      })
    }
  },
  data() {
    return {
      startDatePicker: false,
      endDatePicker: false,

    };
  },
  methods: {
    emitEvents(){
        console.log('fds');
        this.$emit('getEventsByFilter',this.params);
    }
  },
    
  };
  </script>
  <style scoped>
  .date-menu {
    position: absolute !important;
    top: 100% !important;
  }
  </style>